.footer {
    width: 100%;
    border: 2px solid #6a1b9a;
    background-color: white;
    color: black;
    border-left: none;
    border-right: none;
    padding: 1rem;
    position: relative;
    bottom: 0px;
}

.enable-anchor {
    text-decoration: underline;
}