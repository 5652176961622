.divider {
    width: 100%;
    height: 50px;
    border: 2px solid #6a1b9a;
    background-color: white;
    color: black;
    border-left: none;
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.dividerLabel {
    flex: 1;
}