/* General Styles */
body {
    background-color: #28174E;
    font-size: 18px;
    margin: 10px;
    border-color: white;
    /* color: white; */
}

.home {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

/* Navbar Styles */
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2a1e4e; /* Match your site's theme */
    color: #fff;
    padding: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
}

.logo img {
    height: 60px;
    margin-right: 10px;
}

.nav-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav-links li {
    margin: 0 15px;
}

.nav-links a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: #e0b1ff; /* A lighter shade for hover effect */
}

/* Content Area */
.content {
    flex: 1;
    margin: 15px 0;
}

.header {
    text-align: center;
}

.updatetime {
    font-size: 14px;
    float: right;
    margin-right: 3rem;
}

.dbtable {
    margin: 3rem;
}

.tickerInput {
    width: 300px;
}

.pageHeader {
    color: white !important;
}

/* Buttons */
.ui.primary.button,
.ui.primary.buttons .button .searchbtn {
    margin: 10px !important;
    width: 80px;
    border-radius: 40px;
    background: #28174E;
    border: 1px solid white;
}

.ui.primary.button.searchbtn:focus {
    background: #28174E;
}

.ui.primary.button.searchbtn:hover {
    width: 80px;
    border-radius: 40px;
    background: #8884d8;
    border: 1px solid white;
}

/* Visibility Helpers */
.hide {
    display: none;
}

.show {
    display: block;
}

.customTabPane {
    color: white;
    font-weight: bold;
}

.ui.tabular.menu .item {
    color: white;
    font-weight: bold;
}

/* Responsive Design */
@media only screen and (min-width: 480px) {
    .content {
        margin: 3rem;
    }
    .searchSection {
        width: 50vw;
        margin: 0 auto;
    }

}
