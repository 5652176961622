.barChartContainer {
    margin: 10px auto;
    width: 800px;
    height: 500px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.chartLayout {
    width: 100%;
    border: 1px solid white;
}

.companyLabel {
    line-height: 35px;
    font-weight: bold;
    color: white;
}